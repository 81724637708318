import React, { useEffect } from 'react';

// clock
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
// clock css
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

// video
import VideoBg from '../src/assets/video.mp4';
import logo from '../src/assets/alienzmedia-logo.png';
// css
import './styles.css';

const App = () => {
  useEffect(() => {
    document.title = 'Alienz Media - Coming Soon'; // Set the title when the component mounts
  }, []);

  return (
    <section className='page'>
      {/* overlay */}
      <div className='overlay'></div>
      {/* video */}
      <video src={VideoBg} autoPlay loop muted></video>
      <div className='page_content'>
        <img src={logo} alt="Alienz Media Logo" className="logo" />
        <h1 className="alienz-text">
          <span className="orange">ALIENZ</span> <span className="white">MEDIA</span>
        </h1>
        <h3>Bientôt Arriver sur Terre - Coming Soon to Earth</h3>
        <FlipClockCountdown
          to={new Date().getTime() +168 * 3600 * 1000 + 5000}
          className='flip-clock'
          labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
          duration={0.5}
        />
      </div>
    </section>
  );
};

export default App;
